//const url = 'http://127.0.0.1:8081';
//const url = 'https://cotizadorautosdev.isiix.com.mx';
const url = 'https://cotizadorautos.isiix.com.mx';
const v1 = 'v1';
const companyID = 1;
const modeloId = 2024;
const username = 'admin';
const password = 'vm2007.123';
const banorteID = 1;
const afirmeID = 2;
const generalID = 3;
const anaID = 4;
const gnpID = 5;
const qualitasID = 6;
const mapfreID = 7;
const idPaisMexico = 1;
const payCotizacion = 'contado';

export const environment = {
  production: true,
  baseUrl: 'https://isiix.com.mx/wsIsiix/',
  url_base: `${url}/api/${v1}`,
  url: `${url}`,
  urlPago: `${url}`,
  url_mm: 'https://isiix.com.mx/api/v2',
  companyID: `${companyID}`,
  modeloId: `${modeloId}`,
  username_auth: `${username}`,
  password_auth: `${password}`,
  banorte_id: `${banorteID}`,
  afirme_id: `${afirmeID}`,
  general_id: `${generalID}`,
  ana_id: `${anaID}`,
  gnp_id: `${gnpID}`,
  qualitas_id: `${qualitasID}`,
  mapfre_id: `${mapfreID}`,
  pay_form_cotizacion: `${payCotizacion}`,
  auth_endpoint: 'authenticate',
  type_use_endpoint: 'tipouso',
  brand_list_endpoint: 'servicio/marca',
  procedure_endpoint: 'tramite',
  list_model_endpoint: 'modelo',
  list_subrand_endpoint: 'servicio/submarca',
  list_description_endpoint: 'servicio/descripcion',
  type_gender: 'sexo',
  type_user: 'tipopersona',
  type_service: 'caracteristicas',
  cotizacion_validate_endpoint: 'cotizacion',
  cotizaciones_endpoint: 'servicio/cotizacion',
  list_nationality: 'nacionalidad',
  list_estado_endpoint: `pais/${idPaisMexico}/estado`,
  list_type_document_endpoint: 'tipodocumento',
  list_civil_state_endpoint: 'estadocivil',
  contractor_endpoint: 'contratante',
  address_endpoint: 'direccion',
  driver_endpoint: 'conductor',
  vehicle_endpoint: 'vehiculo',
  poliza_endpoint: 'servicio/poliza',
  pdf_poliza_endpoint: 'servicio/poliza/imprimir',
  list_cities_endpoint: 'estado',
  pdf_cotizacion_endpoint: 'cotizacion/email',
  pdf_cotizacion_agente_endpoint: 'cotizacion/agenteemail',
  pdf_cotizacion_agentesin_endpoint: 'cotizacion/agenteemailsin',
  get_MapfreBancosMSI: 'servicio/poliza/mapfre_banks/',
  get_MapfreMSI: 'servicio/poliza/mapfre_banks_msi/',
  localization_endpoint: 'localizacion',
  print_cotizacion_endpoint: 'cotizacion/imprimir',
  send_email_poliza: 'servicio/poliza/email',
  send_email_poliza_withoutpay: 'servicio/poliza/emailwithoutpay'  
};

//Template colors
/*export const envtemplate = {
  principallogo: 'assets/img/template/tec.png',
  footerlogowidth: 115,
  footerbackgroundcolor: '#c8c8c8',
  banner: 'assets/img/01_cotiza_Final-carros.pngno',
  bannerbackground: './assets/img/01_cotiza_Finalno.png',
  tidioChatColor: '#2a3e7e'
}
*/

//PROD
export const envtemplate = {
  principallogo: 'assets/img/logo.png',
  footerlogowidth: 115,
  footerbackgroundcolor: '#88c249',
  banner: 'assets/img/01_cotiza_Final-carros.png',
  bannerbackground: './assets/img/01_cotiza_Final.png',
  tidioChatColor: '#96ca5e'
}
