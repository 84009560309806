export abstract class Localization {
    appTitle: string;
    navTitle: string;
    unexpectedError: string;
    errorMessage: string;
    labelAgent: string;
    labelCustomer: string;
    labelTypeService: string;
    labelBrandCar: string;
    labelOtherBrandCar: string;
    selectRequired: string;
    selectRequiredModel: string;
    selectRequiredSubrand: string;
    selectRequiredTypePerson: string;
    labelRequiredBirthday: string;
    registerOnlyNumber: string;
    registerTenDigits: string;
    registerFiveDigits: string;
    registerCorrectEmail: string;
    inputRequired: string;
    termsRequired: string;
    inputIsAgent: string;
    inputOnly17Caracters: string;
    inputOnly7Caracters: string;
    inputContainSpace: string;
    inputOnlyCaracters: string;
    buttonFormNext: string;
    buttonFormPrev: string;
    labelSelectModel: string;
    labelSelectSubBrand: string;
    labelSelectVersion: string;
    labelSelectDescription: string;
    labelEmail: string;
    labelCP: string;
    labelPhone: string;
    labelNameCustomer: string;
    labelGender: string;
    labelBirthday: string;
    buttonCotizar: string;
    buttonNewCotizar: string;
    labelSelectPlan: string;
    buttonProcesar: string;
    labelSelectPay: string;
    labelIDCotizacion: string;
    labelIDFormaPago: string;
    labelIDPaquete: string;
    labelFirstName: string;
    labelLastNamePaternal: string;
    labelLastNameMaternal: string;
    labelSelectPerson: string;
    labelAge: string;
    labelSelectTypeDoc: string;
    labelNumberDoc: string;
    labelSelectNacionalidad: string;
    labelSelectOcupacion: string;
    labelRFC: string;
    labelTerms: string;
    labelTerms2: string;
    labelPais: string;
    labelCiudad: string;
    labelColonia: string;
    labelCalle: string;
    labelNum: string;
    labelNumExt: string;
    labelSerial: string;
    labelMotor: string;
    labelPlacas: string;
    titleEmisionPoliza: string;
    titleCotizacion: string;
    titlePago: string;
    titlePaquete: string;
    labelTypeUser: string;
    labelSelecServicio: string;
    tablePrimaNeta: string;
    tableMonto: string;
    tableIVA: string;
    tablePrimaTotal: string;
    titleCotizaciones: string;
    linkDetails: string;
    modalCoberturas: string;
    nombreCobertura: string;
    montoSumaAsegurada: string;
    montoPrima: string;
    montoDeducible: string;
    titleBannerPaquete: string;
    titleBannerCotiza: string;
    titleBannerPlan: string;
    titleFormPaquete: string;
    labelEstadoNacimiento: string;
    labelCivilStatus: string;
    labelPhoneHome: string;
    labelSelectState: string;
    labelModel: string;
    labelSubrand: string;
    labelBrand: string;
    labelDescription: string;
    pdfPolizaTitle: string;
    pdfNumberPoliza: string;
    pdfDownload: string;
    repdfDownload: string;
    pdfDownloadPrint: string;
    pdfEndButton: string;
    loaderPdfDownload: string;
    polizaBanner: string;
    bankNotFound: string;
    tryAgain: string;
    selectDay: string;
    selectMonth: string;
    selectYear: string;
    labelRazonSocial: string;
    loaderIntermediate: string;
    titleInicioForm: string;
    labelCurp: string;
    ckeckBoxDriverForm: string;
    page404Title: string;
    page404Text: string;
    labelMensajeExito: string;
    labelMontoPagar: string;
    labelAseguradora: string;
    labelNumeroTarjeta: string;
    labelNombreTitular: string;
    buyButton: string;
    labelConsulta: string;
    labelEstadoCivil: string;
    labelOcupacion: string;
    labelMunicipio: string;
    Compra: string;
    labelFinalizada: string;
    labelCompraEnviada: string;
    labelEmitirPoliza: string;
    alertCodigoPostal: string;
    sendEmailTitle: string;
    sendEmail: string;
    buttonCotizarNuevo: string;
    labelFullName: string;
    labelCreditCardNumber: string;
    labelCCV: string;
    labelValidDate: string;
    buttonProcessPayment: string;
    titleButtonComparacion: string;
}

export class SpanishLocalization implements Localization {
    appTitle = 'Multicotizador';
    navTitle = 'Cotización AUTO';
    unexpectedError = '¡Lo sentimos, ocurrió un error de comunicación!, Por favor intentalo más tarde';
    errorMessage = '¡Lo sentimos, ocurrió un error inesperado!';
    labelAgent = 'Soy Agente';
    labelCustomer = 'Soy Cliente';
    labelTypeService = 'Uso del vehículo:';
    labelBrandCar = '¿Qué marca es tu auto?';
    labelOtherBrandCar = 'Marca:';
    selectRequired = 'Selecciona una opción';
    labelRequiredBirthday = 'Debes ingresar tu fecha de nacimiento';
    selectRequiredModel = 'Selecciona un modelo';
    selectRequiredSubrand = 'Selecciona una submarca';
    selectRequiredTypePerson = 'Selecciona tipo de persona';
    registerOnlyNumber = 'Es necesario capturar solo números';
    registerTenDigits = 'Escribe solo 10 dígitos númericos';
    register13Digits = 'Escribe solo 13 caractéres';
    registerFiveDigits = 'Escribe los 5 dígitos de tu código postal';
    registerCorrectEmail = 'Escribe un correo válido';
    inputRequired = 'Este campo es requerido';
    termsRequired = 'Es necesario aceptar términos y condiciones para continuar';
    inputIsAgent = 'Escribe solo 10 dígitos númericos';
    inputOnly17Caracters = 'Deben ser solo 17 carácteres';
    inputOnly7Caracters = 'Deben ser solo 7 carácteres';
    inputContainSpace = 'No debe tener espacios en blanco';
    inputOnlyCaracters = 'Solo se aceptan carácteres alfanúmericos';
    buttonFormNext = 'Continuar';
    buttonFormPrev = 'Regresar';
    labelSelectModel = 'Modelo:';
    labelSelectSubBrand = 'Submarca:';
    labelSelectVersion = 'Seleccione versión:';
    labelSelectDescription = 'Descripción:';
    labelEmail = 'Correo eléctronico:';
    labelCP = 'Código postal:';
    labelPhone = 'Teléfono móvil:';
    labelRfc = 'Rfc:';
    labelTerms = 'He leído y acepto el '
    labelTerms2 = 'Así mismo otorgo expresamente mi consentimiento para recibir notificaciones, información y documentación del contrato de seguro que se propone, por los medios electrónicos referidos en mi información y hasta en tanto les sea notificado su cambio.'
    labelNameCustomer = 'Nombre:';
    labelGender = 'Género:';
    labelBirthday = 'Fecha de nacimiento:';
    buttonCotizar = 'Contratar';
    buttonCotizarDescuento = 'Aprovecha, tenemos un mejor descuento para ti.';
    buttonCotizarDescuentoMapfre = 'Aprovecha, este es nuestro mejor precio, ¡Contrátalo ya!';
    buttonNewCotizar = 'Cotizar de nuevo';
    labelSelectPlan = 'Seleccione el tipo de plan a escoger';
    buttonProcesar = 'Procesar';
    labelSelectPay = 'Seleccione una forma de pago';
    labelIDCotizacion = 'Cotización';
    labelIDFormaPago = 'Pago';
    labelIDPaquete = 'Paquete';
    labelFirstName = 'Nombre:';
    labelLastNamePaternal = 'Apellido Paterno:';
    labelLastNameMaternal = 'Apellido Materno:';
    labelSelectPerson = 'Tipo de Persona:';
    labelAge = 'Edad:';
    labelSelectTypeDoc = 'Tipo de documento:';
    labelNumberDoc = 'Número de Documento:';
    labelSelectNacionalidad = 'Nacionalidad:';
    labelSelectOcupacion = 'Ocupación:';
    labelRFC = 'RFC:';
    labelPais = 'País:';
    labelCiudad = 'Ciudad:';
    labelColonia = 'Colonia:';
    labelCalle = 'Calle:';
    labelNum = 'N° Interno:';
    labelNumExt = 'N° Externo:';
    labelSerial = 'Número de Serie o NIV (17 Dígitos):';
    labelMotor = 'Número de Motor:';
    labelPlacas = 'Placas:';
    titleEmisionPoliza =  'Emisión de pólizas';
    titleCotizacion = 'Cotización';
    titlePago = 'Pago';
    titlePaquete = 'Paquete';
    labelTypeUser = 'Tipo persona';
    labelSelecServicio = 'Seleccione un servicio';
    tablePrimaNeta = 'Prima neta';
    tableMonto = 'Derechos de póliza';
    tableIVA = 'IVA';
    tablePrimaTotal = 'Prima total';
    titleCotizaciones = 'Selecciona tu producto';
    linkDetails = 'Coberturas';
    modalCoberturas = 'Coberturas';
    nombreCobertura = 'Nombre de Cobertura';
    montoSumaAsegurada = 'Suma asegurada';
    montoPrima = 'Monto Prima';
    montoDeducible = 'Deducible';
    titleBannerPaquete = 'Seguros';
    titleBannerCotiza = 'Cotizador de Autos';
    titleBannerPlan = 'Tipo de plan';
    titleFormPaquete = 'Selecciona un paquete';
    labelEstadoNacimiento = 'Estado de nacimiento:';
    labelCivilStatus = 'Estado civil:';
    labelPhoneHome = 'Teléfono:';
    labelSelectState = 'Estado';
    labelModel = 'Modelo:';
    labelSubrand = 'Submarca:';
    labelBrand = 'Marca:';
    labelDescription = 'Descripción:';
    pdfPolizaTitle = '¡Felicidades! tu póliza fue creada con éxito';
    pdfNumberPoliza = 'Número de Póliza: ';
    pdfDownload = 'Descargar póliza';
    repdfDownload = 'Reenviar póliza';
    pdfDownloadPrint = 'No olvides imprimir tu póliza';
    pdfEndButton = 'Finalizar';
    loaderPdfDownload = 'Descargando póliza. . .';
    polizaBanner = 'Póliza';
    bankNotFound = '¡Lo sentimos, no encontramos ninguna aseguradora!';
    tryAgain = 'Por favor intenta cotizar de nuevo';
    selectDay = 'Selecciona día de nacimiento';
    selectMonth = 'Selecciona mes de nacimiento';
    selectYear = 'Selecciona año de nacimiento';
    labelRazonSocial = 'Razón social';
    loaderIntermediate = 'Buscando los mejores precios ...';
    titleInicioForm = 'Para comenzar elige el uso o servicio que prestará el vehículo.';
    labelCurp = 'CURP:';
    ckeckBoxDriverForm = 'Utilizar los mismos datos del contratante';
    page404Title = 'Página no encontrada';
    page404Text = 'Lo sentimos, esta página no existe o no esta disponible';
    labelMensajeExito = '¡Espera un momento, estamos realizando tu pago!';
    labelMontoPagar = 'Monto a Pagar';
    labelAseguradora = 'Aseguradora';
    labelNumeroTarjeta = 'Número de Tarjeta';
    labelNombreTitular = 'Nombre que aparece en Tarjeta';
    buyButton = 'Pagar';
    labelConsulta = 'Tipo de consulta:';
    labelEstadoCivil = 'Estado civil';
    labelOcupacion = 'Ocupación';
    labelMunicipio = 'Municipio';
    Compra = 'Compra';
    labelFinalizada = 'Finalizada';
    labelCompraEnviada = 'Consulta la póliza al correo que nos proporcionaste.';
    labelEmitirPoliza = 'Emitir Póliza';
    alertCodigoPostal = '¡Código postal inválido!';
    sendEmailTitle = 'Confirmación de envío';
    sendEmail = '¡Tu cotización ha sido enviada!';
    buttonCotizarNuevo = 'Cotizar de nuevo';
    buttonConfirmar = 'Confirmar';
    labelFindByDescriptionAuto = '¿Tu auto no aparece? Filtra por descripción.';
    labelFullName = 'Nombre del titular';
    labelCreditCardNumber = 'Número de la tarjeta';
    labelCCV = 'CVC';
    labelValidDate = 'MM/AA';
    buttonProcessPayment = 'Pagar';
    inputSpecialChars = 'No utilizar caracteres especiales, reemplazar Ñ por N y no utilizar acentos.';
    descuentoBanorte='';
    descuentoGeneralSeguros='';
    descuentoAnaSeguros='';
    descuentoAtlas='';
    descuentoQualitas='';
    descuentoMapfre='';
    descuentoGNP='';
    titleButtonComparacion='Comparar cotizaciones';
    mapfreerror='Ocurrió un error al intentar emitir la poliza.'
}
